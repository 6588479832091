import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

const PhotoGallery = () => {
  return (
    <Layout>
      <Metadata title="Photo Gallery" description="Our beach cleanup photos and photos that show the beauty of Long Island's beaches." />
      <div>
        <h1>Photo Gallery</h1>
        <p>Lorem ipsum.</p>
      </div>
    </Layout>
  )
}

export default PhotoGallery